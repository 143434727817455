import { detectIE, supportedIEVersion } from './detectIE'
// Get IE or Edge browser version
const version = detectIE()
const $ = window.jQuery

$(($) => {
  setTimeout(() => {
    $('.preloader').fadeOut(300)
  }, 1000)

  if (version !== false) {
    if (version < supportedIEVersion) {
      confirm(
        'We pride ourselves on using the latest, secure web technologies and therefore this website will not display correctly in this old browser. This version of Internet Explorers is at least 7 years old and is no longer supported by Microsoft. We highly recommend using a modern browser that is secure such as Google Chrome - https://www.google.com/chrome/.',
      )
    } else if (version === supportedIEVersion) {
      window.UIkit.modal.alert(
        'We pride ourselves on using the latest, secure web technologies and therefore this website will not display correctly in this old browser. We highly recommend using a modern browser that is secure such as Google Chrome - <a href="https://www.google.com/chrome/" target="_blank">https://www.google.com/chrome/</a>.',
      )
    }
  }

  $('#checkCode').submit((event) => {
    event.preventDefault()
    const code = $('#checkCode [name="code"]').val()
    if (code) {
      $.get('/action/redirect-request/with-handle', {
        code,
      }).done((data) => {
        if (data.url === null) {
          $('#code-error').removeClass('uk-hidden')
        } else {
          location.assign(data.url)
        }
      })
    }
  })
})
